import "@brainhubeu/react-carousel/lib/style.css"

import React, { Suspense, useState, forwardRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { path } from "../../../lib/util"

import { BgImage } from "gbimage-bridge"
// import Carousel from "@brainhubeu/react-carousel"
const Carousel = React.lazy(() => import("@brainhubeu/react-carousel"))

import Inner from "../../layout/pageInner/Inner"
import SvgIcon from "../../atoms/icons/svg-icon"
import LinkWrapper from "../../../utils/linkWrapper"
import ChevronRight from "../../atoms/icons/files/chevron-right.jsx"
import ChevronLeft from "../../atoms/icons/files/chevron-left.jsx"

const SleepMatters = ({
  title = "Content Hub",
  subTitle = "We write and curate the best sleeping and related articles to help you get the best night sleep of your life.",
  background = "#233548",
}) => {
  // Extract graphql fields
  const imgData = useStaticQuery(PLACEHOLDER)
  const {
    localWpGraphQlPages: {
      home_page: { postCarouselRow },
    },
  } = imgData

  // Define states
  const posts = imgData.posts.nodes

  return (
    <Row background={background}>
      <TextWrap>
        <h2>{postCarouselRow.title || title}</h2>
        <h3>
          {postCarouselRow.subTitle || subTitle}
          <br />
          Read our latest articles here:
        </h3>
      </TextWrap>
      <Suspense fallback={<div>Loading...</div>}>
        <Inner>
          <Grid>
            {posts.map((node, i) => {
              return (
                <PostLink
                  title={node.title}
                  slug={node.slug}
                  key={i}
                  image={
                    path(
                      [
                        "featuredImage",
                        "imageFile",
                        "localFile",
                        "childImageSharp",
                        "gatsbyImageData",
                      ],
                      node
                    ) || imgData.file.childImageSharp.gatsbyImageData
                  }
                />
              )
            })}
          </Grid>
        </Inner>
      </Suspense>
    </Row>
  )
}

export default SleepMatters

const PostLink = forwardRef(({ image, title, slug = "/" }, ref) => {
  return (
    <LinkWrapper to={`/sleep-matters/${slug}`}>
      <StyledBackground image={image}>
        <Overlay>
          <p dangerouslySetInnerHTML={{ __html: title }} />
        </Overlay>
      </StyledBackground>
    </LinkWrapper>
  )
})

const Overlay = styled.div`
  // min-height: 175px;
  min-height: 220px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 47.38%,
    rgba(0, 0, 0, 0.44) 100%
  );
  p {
    padding: 15px;
    color: white;
    font-family: Roboto;
    font-size: 20px;
    line-height: 24px;
  }
`

const StyledBackground = styled(BgImage)`
  min-width: 250px;
  cursor: pointer;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
`

const Row = styled.div`
  background: ${({ background }) => background};
  //padding: 70px 0 100px 0;
  padding: 70px 0 70px 0;
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    a {
      margin: 10px;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  //padding: 40px 0;

  @media (max-width: 1160px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const TextWrap = styled(Inner)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  h2 {
    color: white;
    border-bottom: 1px solid red;
  }
  h3 {
    color: #fff;
    font-size: 16px;
    margin-top: 10px;
  }
`
const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    posts: allLocalWpGraphQlPosts(limit: 4) {
      nodes {
        uri
        title
        slug
        postId
        id
        date(fromNow: true)
        excerpt
        featuredImage {
          sourceUrl
          mediaItemId
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, breakpoints: 500)
              }
            }
          }
        }
      }
    }

    localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        postCarouselRow {
          subTitle
          title
        }
      }
    }
  }
`
