import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Inner from "../../zzz/layout/pageInner/Inner"
import styled from "styled-components"

import ProductList from "./ProductList"
// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const FrontPageSpecials = () => {
  const graphqlData = useStaticQuery(FEATUREDPRODUCTS)
  const info = graphqlData?.localWpGraphQlPages?.home_page?.specialsRow || {}

  console.log(graphqlData)

  var selectedProducts = []
  info.limitedSpecialsProducts.forEach(
    ({ limitedSpecialParent, limitedSpecialVariation }) => {
      graphqlData.allWcProducts.nodes.forEach((product) => {
        if (product.wordpress_id === limitedSpecialParent) {
          if (limitedSpecialVariation?.length > 0) {
            var temp_product = product
            temp_product.acf.featured_variations = limitedSpecialVariation
            temp_product.onlyShow = limitedSpecialVariation
            selectedProducts.push(temp_product)
          } else {
            var temp_product = product
            temp_product.acf.featured_variations = []
            selectedProducts.push(temp_product)
          }
        }
      })
    }
  )

  return (
    <Row>
      <Inner>
        <TextWrap>
          <Title>{info.title}</Title>
          <SubTitle>{info.subTitle}</SubTitle>
        </TextWrap>
      </Inner>
      <StyledInner>
        <ProductList
          hideExtraProducts
          showProductDeals
          // productList={graphqlData.allWcProducts.nodes}
          productList={selectedProducts}
          productsPerLoad={8}
          sizesFilter={false}
        />
      </StyledInner>
    </Row>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const StyledInner = styled(Inner)`
  margin-top: 30px;
  min-height: 200px;
`
const Row = styled.div`
  background: #fff;
  padding: 30px 0;
`
const TextWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.h2`
  color: #223247;
  border-bottom: 1px solid red;
`
const SubTitle = styled.h3`
  color: #223247;
  font-size: 16px;
  margin-top: 10px;
`

export default FrontPageSpecials

const FEATUREDPRODUCTS = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        specialsRow {
          title
          subTitle
          limitedSpecialsProducts {
            limitedSpecialParent
            limitedSpecialVariation
          }
        }
      }
    }
    ## ProductFilter: All featured products
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
      }
    ) {
      nodes {
        ...ProductCardInfo
      }
    }
  }
`
