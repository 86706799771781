import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/SEO/seo"
import FrontPageSpecials from "../components/Products/FrontPageSpecials"

// import { Responsive, windowSizes } from "../utils/responsive"

import Layout from "../zzz/layout"
import PageInner from "../zzz/layout/pageInner"
import ValueProposition from "../zzz/atoms/valueProposition"

import Slider from "../zzz/organisms/Slider"
import LazyLoadWrapper from "../utils/lazyLoadWrapper"
import CarouselRow from "../zzz/organisms/CarouselRow"
import AboutRow from "../zzz/organisms/about"
import InfoRow from "../zzz/organisms/InfoRow"
import CtaRow from "../zzz/organisms/ctaRow"
import BedFinderRow from "../zzz/organisms/ctaRow/BedFinderRow"
// import Inner from "../zzz/layout/pageInner/Inner"
// import ReactPlayer from "react-player/youtube"
import styled from "styled-components"
import SleepMatters from "../zzz/organisms/SleepMatters"
// import ReviewRatingsWidget from "../zzz/organisms/reviewRatingsWidget"

const IndexPage = ({ location }) => {
  const data = useStaticQuery(HOME_PAGE_QUERY)

  const {
    localWpGraphQlPages: { seo },
  } = data

  return (
    <Layout location={location}>
      <SEO title="Home" location={location} post_id={194} seo={seo} />
      <PageInner padding="0">
        <Slider />
        <ValueProposition />
        {/*<BedFinderAndSpecials />*/}
        <FrontPageSpecials />
        <LazyLoadWrapper
          placeholderHeight={"642px"}
          lazyComponent={<BedFinderRow />}
        />
        <AboutRow />
        <LazyLoadWrapper
          lazyComponent={<InfoRow show="publications" cols={3} />}
        />
        {/*<LazyLoadWrapper lazyComponent={<ReviewRatingsWidget />} />*/}
        {/*<LazyLoadWrapper lazyComponent={<CtaRow />} />*/}
        {/*<LazyLoadWrapper lazyComponent={<CarouselRow />} />*/}
        <LazyLoadWrapper lazyComponent={<SleepMatters />} />
        <LazyLoadWrapper lazyComponent={<InfoRow show="brands" />} />
      </PageInner>
    </Layout>
  )
}

// const BedFinderAndSpecials = () => {
//   // Yeah it looks silly, but they wanted it swapped on mobile
//   return (
//     <>
//       <Responsive maxWidth={windowSizes.tablet}>
//         <LazyLoadWrapper
//           placeholderHeight={"642px"}
//           lazyComponent={<BedFinderRow />}
//         />
//         <FrontPageSpecials />
//       </Responsive>
//       <Responsive minWidth={windowSizes.tablet}>
//         <FrontPageSpecials />
//         <LazyLoadWrapper
//           placeholderHeight={"642px"}
//           lazyComponent={<BedFinderRow />}
//         />
//       </Responsive>
//     </>
//   )
// }

export default IndexPage

const HOME_PAGE_QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      home_page {
        specialsRow {
          subTitle
          title
        }
      }
    }
  }
`

const Wrap = styled.div`
  &.videoArea {
    padding: 100px 0;
  }
`
