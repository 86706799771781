import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import { Button } from "./DesktopSlider"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const MobileSlider = ({
  image,
  mobileImage,
  title,
  subTitle,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <Container>
      <StyledBackground
        image={mobileImage || image}
        alt="slider image"
        loading="eager"
      />
      <Content>
        <h2>{title}</h2>
        <p>{subTitle}</p>
        <Button to={buttonLink}>
          <div>{buttonLabel} </div>
          <SvgIcon SvgComponent={ArrowRight} hue="danger" shade="fontColor" />
        </Button>
      </Content>
    </Container>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Container = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: block;
  }
`
const Content = styled.div`
  position: relative;
  padding: 20px;
  .arrow-icon {
    margin: 0;
    position: absolute;
    top: 0;
    background: #fd3237;
    transform: translateY(-50%);
    &.right {
      right: 0;
      border-radius: 3px 0 0 3px;
    }
    &.left {
      border-radius: 0 3px 3px 0;
      left: 0;
    }
    &:hover {
      transform: translateY(-50%);
    }
  }
  h2 {
    color: #f0f1f3;
    font-family: Roboto;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 500;
    text-transform: uppercase;
  }
  p {
    color: #bac0c5;
    font-family: Roboto;
    font-size: 18px;
    margin-bottom: 10px;
  }
`

const StyledBackground = styled(GatsbyImage)`
  position: relative;
  opacity: 0.99;
  width: 100%;
  min-height: 75vw;
`

export default MobileSlider
