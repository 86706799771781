import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const DesktopSlider = ({ image, title, subTitle, buttonLabel, buttonLink }) => {
  return (
    <>
      <StyledBackground image={image} alt="banner-image" loading="eager" />
      <Overlay>
        <Content>
          <h1 className="shadowed">{title}</h1>
          <p className="shadowed">{subTitle}</p>
          <Button to={buttonLink}>
            <div>{buttonLabel} </div>
            <SvgIcon SvgComponent={ArrowRight} hue="danger" shade="fontColor" />
          </Button>
        </Content>
      </Overlay>
    </>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Content = styled.div`
  margin: 0 105px;
  h1.shadowed {
    text-shadow: 0px 0px 8px black;
    color: #f0f1f3;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 500;
    line-height: 43px;
    text-transform: uppercase;
    max-width: 460px;
  }
  p.shadowed {
    text-shadow: 0px 0px 5px black;
    color: #f0f1f3;
    font-family: Roboto;
    font-size: 20px;
    line-height: 24px;
    margin: 20px 0;
  }
`

export const Button = styled(Link)`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
  }

  > div {
    padding: 0 10px;
  }

  > span {
    padding: 5px;
    background: #da2e32;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 21vw;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

const StyledBackground = styled(GatsbyImage)`
  display: block;
  position: relative;
  opacity: 0.99;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

export default DesktopSlider
