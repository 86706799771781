import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { BgImage } from "gbimage-bridge"
import Inner from "../../layout/pageInner/Inner"
import SvgIcon from "../../atoms/icons/svg-icon"
import Search from "../../atoms/icons/files/search.jsx"
import LinkWrapper from "../../../utils/linkWrapper"
import { getImage } from "../../../lib/util"
import ShoppingCart from "../../atoms/icons/files/shopping-cart"

const BedFinderRow = () => {
  // Extract graphql fields
  const {
    localWpGraphQlPages: {
      home_page: { altCtaRow },
    },
  } = useStaticQuery(BED_FINDER_QUERY)

  return (
    <StyledBackground
      image={getImage(altCtaRow.backgroundImage)}
      style={{
        backgroundPosition: "left center",
      }}
    >
      <Inner>
        <div className="content-wrapper">
          <h2>{altCtaRow.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: altCtaRow.body }} />
          <Button to="/bed-finder/" className={"mb-15"}>
            <SvgIcon
              SvgComponent={Search}
              hue="danger"
              size="md"
              shade="fontColor"
            />
            <div>Let's find your bed</div>
          </Button>
          <Button to="/bed-specials/">
            <SvgIcon
              SvgComponent={ShoppingCart}
              hue="danger"
              size="md"
              shade="fontColor"
            />
            <div>Shop Sale</div>
          </Button>
        </div>
      </Inner>
    </StyledBackground>
  )
}

export default BedFinderRow

const Button = styled(LinkWrapper)`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
  }
  &.mb-15 {
    margin-bottom: 15px;
  }
  > div {
    padding: 0 15px;
    font-size: 20px;
  }
  > span {
    padding: 8px;
    background: #da2e32;
  }
`

const StyledBackground = styled(BgImage)`
  border-top: 1px solid ${({ theme }) => theme.colors.tusk["020"]};
  padding: 200px 0;
  .content-wrapper {
    max-width: 50%;
  }
  h2 {
    color: #223247;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 300;
    line-height: 57px;
    margin-bottom: 20px;
    max-width: 600px;
  }
  p {
    color: #223247;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 20px;

    max-width: 600px;
  }
  @media (max-width: 1280px) {
    padding: 150px 0;
    .content-wrapper {
      max-width: 60%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 100px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .content-wrapper {
      max-width: 80%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .content-wrapper {
      max-width: 100%;
      a {
        width: 100%;
      }
    }
  }
`

const BED_FINDER_QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        altCtaRow {
          body
          title
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    breakpoints: [480, 750, 1080, 1366]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
