import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { path } from "../../../lib/util"
import DesktopSlider from "./DesktopSlider"
import MobileSlider from "./MobileSlider"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Slider = () => {
  const data = useStaticQuery(SLIDES_QUERY)
  const slides = data.localWpGraphQlPages.slider.slides

  // Prepare slide data
  const slideProps = {
    image: path(
      ["image", "imageFile", "localFile", "childImageSharp", "gatsbyImageData"],
      slides[0]
    ),
    mobileImage: path(
      [
        "mobileImage",
        "imageFile",
        "localFile",
        "childImageSharp",
        "gatsbyImageData",
      ],
      slides[0]
    ),
    title: path(["title"], slides[0]),
    subTitle: path(["subTitle"], slides[0]),
    buttonLabel: path(["button", "title"], slides[0]),
    buttonLink: path(["button", "link"], slides[0]),
  }

  return (
    <Wrap>
      <MobileSlider {...slideProps} />
      <DesktopSlider {...slideProps} />
    </Wrap>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Wrap = styled.div`
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  background: #1a293c;
`

export default Slider

const SLIDES_QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      slider {
        slides {
          image {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    layout: FULL_WIDTH
                    breakpoints: [750, 1080, 1366]
                  )
                }
              }
            }
          }
          mobileImage {
            sourceUrl
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    layout: FULL_WIDTH
                    breakpoints: [300, 600]
                  )
                }
              }
            }
          }
          title
          subTitle
          button {
            title
            link
          }
        }
      }
    }
  }
`
