import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { BgImage } from "gbimage-bridge"
import SvgIcon from "../../atoms/icons/svg-icon"
import Search from "../../atoms/icons/files/search.jsx"
import ShoppingCart from "../../atoms/icons/files/shopping-cart.jsx"
import LinkWrapper from "../../../utils/linkWrapper"
import { getImage } from "../../../lib/util"

const CtaRow = () => {
  // Extract graphql fields
  const {
    localWpGraphQlPages: {
      home_page: { ctaRow },
    },
  } = useStaticQuery(CTA_ROW_QUERY)

  return (
    <StyledBackground image={getImage(ctaRow.backgroundImage)}>
      <div className="overlay">
        <h2>{ctaRow.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: ctaRow.body }} />
        <div className="btns">
          {ctaRow.buttons.title.map(({ title, link }, i) => (
            <Button to={link} key={i}>
              <SvgIcon
                SvgComponent={i === 0 ? Search : ShoppingCart}
                hue="danger"
                size="md"
                shade="fontColor"
              />
              <div>{title}</div>
            </Button>
          ))}
        </div>
      </div>
    </StyledBackground>
  )
}

export default CtaRow

const Button = styled(LinkWrapper)`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
  > div {
    padding: 0 15px;
    font-size: 20px;
  }
  > span {
    padding: 8px;
    background: #da2e32;
  }
`

const StyledBackground = styled(BgImage)`
  h2 {
    color: #ffffff;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 300;
    line-height: 57px;
    text-align: center;
    max-width: 900px;
    margin: 20px auto;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 30px;
      font-weight: 300;
      line-height: 35px;
    }
  }
  p {
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-weight: light;
    }
  }
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    a {
      margin: 10px;
    }
  }
  .overlay {
    padding: 100px 20px;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(22, 34, 50, 0) 0%,
      rgba(22, 34, 50, 0.3) 100%
    );
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .btns {
      display: block;
      a {
        width: calc(100% - 20px);
      }
    }
  }
`

const CTA_ROW_QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        # Brands logos, the "We stock the following brands" section
        ctaRow {
          body
          title
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    breakpoints: [480, 750, 1080]
                  )
                }
              }
            }
          }
          buttons {
            title {
              link
              title
            }
          }
        }
      }
    }
  }
`
